
import { Component } from 'vue-property-decorator';
import { RouteConfig, Route, RawLocation } from 'vue-router';
import SubModule from '../../components/SubModule.vue';
import routes from './routes';
import { store } from './store';
import { EntityStoreGetters } from '@/base';
import { IMenuItem } from '../../components/Menu.vue';

@Component
export default class CashAccountTransaction extends SubModule
{
  private get activeItem(): typeof store[EntityStoreGetters.ACTIVE_ITEM]
  {
    return store[EntityStoreGetters.ACTIVE_ITEM];
  }

  protected _getRoutes(): RouteConfig[]
  {
    return routes;
  }

  protected _getDynamicMenuItems(): IMenuItem[]
  {
    const activeItem = this.activeItem;
    if (activeItem) {
      if (activeItem.$isCreated()) {
        return [
          {
            title: `${activeItem.id}`,
            icon: `$vuetify.icons.edit`,
            route: `/cashaccounttransaction/${activeItem.id}`,
          },
        ];
      }
      else {
        return [
          {
            title: this.$t('form.titleNew').toString(),
            icon: `$vuetify.icons.add`,
            route: `/cashaccounttransaction/new`,
          },
        ];
      }
    }
    return [];
  }
}
